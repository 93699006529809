import React, { useContext, useState } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import useAuth from 'auth/UseAuth';
import { CancelButton } from 'components/CancelButton';
import { PrimaryButton } from 'components/PrimaryButton';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';
import { DialogCloseButton } from '../../components/DialogCloseButton';
import { DialogOpenButton } from '../../components/DialogOpenButton';
import { ErrorIndicator } from '../../components/ErrorIndicator';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { ErrorManagement, LoadingState } from '../../components/LoadingStateUtil';
import PipelineRulesContext from '../../contexts/PipelineRulesContext';
import { CreateDataClass, SuperDuperFiestaDataClass, SuperDuperFiestaEntity } from '../../data/SuperDuperFiestaData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';

export const CreateDataClassModal = ({ entities }: { entities: SuperDuperFiestaEntity[] | null }) => {
  const { accessToken, user } = useAuth();
  const { t } = useMemoTranslation();

  const { pipelineFiltersDispatch, pipelineFiltersState } = useContext(PipelineRulesContext);

  const [loadingState, setLoadingState] = useState<LoadingState>({ status: 'NotStarted' });
  const [open, setOpen] = useState<boolean>(false);
  const [name, setName] = useState<string>();
  const [selectedEntity, setSelectedEntity] = useState<SuperDuperFiestaEntity | null>(null);

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleSave = async () => {
    ErrorManagement('Loading', setLoadingState, async () => {
      if (!selectedEntity || !name) {
        return;
      }

      const newDataClass: SuperDuperFiestaDataClass = {
        name: name,
        entityId: selectedEntity.entityId,
        createdBy: user?.name,
      };

      const response = await CreateDataClass(newDataClass, accessToken);
      pipelineFiltersDispatch({
        type: 'update_dataClasses',
        dataClasses: [response, ...pipelineFiltersState.dataClasses],
      });
      handleClose();
    });
  };

  const handleClose = () => {
    setOpen(false);
    setName('');
    setSelectedEntity(null);
    setLoadingState({ status: 'NotStarted' });
  };

  return (
    <>
      <DialogOpenButton onClick={handleOpen} sx={{ pr: 0, pl: 0, minWidth: '10%' }} />
      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth='sm'>
        <DialogTitle>
          <DialogCloseButton onClick={handleClose} />
          Create Data Class
        </DialogTitle>
        <DialogContent>
          <Box component='form' sx={{ flexGrow: 1, mt: 1 }}>
            <TextField
              fullWidth
              size='small'
              label='Name'
              sx={{ mb: '4px', mt: '8px' }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setName(event.target.value);
              }}
            />
            <AutocompleteWrapper
              fullWidth
              size='small'
              value={selectedEntity}
              options={entities || []}
              getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
              renderInput={params => <TextField {...params} label={t('entity')} margin='dense' required />}
              onChange={(_, value) => setSelectedEntity(value)}
            />
          </Box>
        </DialogContent>
        <LoadingIndicator loadingState={loadingState} margin={'LR'} />
        <ErrorIndicator loadingState={loadingState} />
        <DialogActions>
          <CancelButton onClick={handleClose} disabled={loadingState.status === 'Loading'}>
            Cancel
          </CancelButton>
          <PrimaryButton onClick={handleSave} disabled={loadingState.status === 'Loading'}>
            Save
          </PrimaryButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
