import React, { useContext, useEffect } from 'react';
import { TextField } from '@mui/material';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';
import { ActionButton } from '../../components/DialogOpenButton';
import { FlexBox } from '../../components/FlexBox';
import { LoadState, LoadingProps } from '../../components/LoadingStateUtil';
import PipelineRulesContext from '../../contexts/PipelineRulesContext';
import {
  SuperDuperFiestaEntity,
  SuperDuperFiestaPipeline,
  SuperDuperFiestaStage,
} from '../../data/SuperDuperFiestaData';
import useMemoTranslation from '../../hooks/UseMemoTranslation';

export const PipelineRules = ({
  loadingProps,
  selectedEntity,
  selectedPipeline,
  selectedStage,
  setSelectedEntity,
  setSelectedPipeline,
  setSelectedStage,
}: {
  loadingProps: LoadingProps;
  selectedEntity: SuperDuperFiestaEntity | null;
  selectedPipeline: SuperDuperFiestaPipeline | null;
  selectedStage: SuperDuperFiestaStage | null;
  setSelectedEntity: React.Dispatch<React.SetStateAction<SuperDuperFiestaEntity | null>>;
  setSelectedPipeline: React.Dispatch<React.SetStateAction<SuperDuperFiestaPipeline | null>>;
  setSelectedStage: React.Dispatch<React.SetStateAction<SuperDuperFiestaStage | null>>;
}) => {
  const { t } = useMemoTranslation();
  const { getFilterData, pipelineFiltersState } = useContext(PipelineRulesContext);

  const filteredStages = ['promoteToAugmentation', 'promoteToSelection'];

  const [, setLoadingState] = loadingProps;

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      await getFilterData();
    });
  }, [getFilterData, setLoadingState]);

  useEffect(() => {
    if (selectedStage === null) {
      setSelectedStage(pipelineFiltersState.stages.find(s => s.name === 'transformation') || null);
    }
  }, [pipelineFiltersState.stages, selectedStage, setSelectedStage]);

  useEffect(() => {
    if (selectedEntity === null) {
      setSelectedEntity(pipelineFiltersState.entities.find(e => e.name === 'lab') || null);
    }
  }, [pipelineFiltersState.entities, selectedEntity, setSelectedEntity]);

  function handleClear() {
    setSelectedPipeline(null);
    setSelectedStage(null);
    setSelectedEntity(null);
    setLoadingState({ status: 'NotStarted' });
  }

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1, width: '100%' }}>
      <AutocompleteWrapper
        fullWidth
        value={selectedPipeline}
        options={pipelineFiltersState.pipelines}
        getOptionLabel={option => (typeof option === 'string' ? option : option.name)}
        isOptionEqualToValue={(option: any, value: any) =>
          typeof option === 'string' ? option === value : option.name === value.name
        }
        renderInput={params => <TextField {...params} label={t('pipeline')} margin='dense' required />}
        onChange={(_, value) => setSelectedPipeline(value)}
      />
      <AutocompleteWrapper
        fullWidth
        value={selectedStage}
        options={pipelineFiltersState.stages.filter(s => !filteredStages.includes(s.name))}
        getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
        isOptionEqualToValue={(option: any, value: any) =>
          typeof option === 'string' ? option === value : option.displayName === value.displayName
        }
        renderInput={params => <TextField {...params} label={t('stage')} margin='dense' required />}
        onChange={(_, value) => setSelectedStage(value)}
      />
      <AutocompleteWrapper
        fullWidth
        value={selectedEntity}
        options={pipelineFiltersState.entities}
        getOptionLabel={option => (typeof option === 'string' ? option : option.displayName)}
        isOptionEqualToValue={(option: any, value: any) =>
          typeof option === 'string' ? option === value : option.displayName === value.displayName
        }
        renderInput={params => <TextField {...params} label={t('entity')} margin='dense' required />}
        onChange={(_, value) => setSelectedEntity(value)}
      />
      <ActionButton
        sx={{ p: 0 }}
        title={'Clear Filters'}
        onClick={() => {
          handleClear();
        }}
        disableMarginLeft={true}
      >
        <></>
      </ActionButton>
    </FlexBox>
  );
};
