import React from 'react';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Typography } from '@mui/material';
import { FlexBox } from 'components/FlexBox';
import { FormTextField } from 'components/form/FormTextField';
import {
  Control,
  FieldArray,
  UseFieldArrayAppend,
  UseFieldArrayUpdate,
  UseFieldArrayRemove,
  UseFormTrigger,
} from 'react-hook-form';
import { IRuleFormInput, defaultUpdateValue } from '../../../contexts/PipelineRulesContext';

export const StepUpdateValueList = ({
  control,
  trigger,
  fields,
  append,
  remove,
  update,
  maxIndex = undefined,
}: {
  control: Control<IRuleFormInput, any>;
  trigger: UseFormTrigger<IRuleFormInput>;
  fields: FieldArray<IRuleFormInput, 'stepUpdateValues'>[];
  append: UseFieldArrayAppend<IRuleFormInput, 'stepUpdateValues'>;
  remove: UseFieldArrayRemove;
  update: UseFieldArrayUpdate<IRuleFormInput, 'stepUpdateValues'>;
  maxIndex?: number | undefined;
}) => {
  const handleRemove = (index: number) => {
    const selectedField = fields[index];
    selectedField.stepUpdateValueId !== undefined ? update(index, { ...selectedField, removed: true }) : remove(index);
  };

  function updateValueRow(field: FieldArray<IRuleFormInput, 'stepUpdateValues'>, index: number) {
    if (field.removed) {
      return;
    }
    if (maxIndex) {
      // Get count of removed suvs in range of maxIndex and add to it
      const removedCount = fields.slice(0, maxIndex).filter(f => f.removed).length;
      const updatedMaxIndex = maxIndex + removedCount;
      if (index >= updatedMaxIndex) {
        return;
      }
    }
    return (
      <FlexBox key={field.id} sx={{ mt: 1 }}>
        <FormTextField
          name={`stepUpdateValues.${index}.field`}
          control={control}
          label='Field'
          trigger={trigger}
          sx={{ mr: '4px' }}
        />
        <FormTextField name={`stepUpdateValues.${index}.value`} control={control} label='Value' trigger={trigger} />
        <IconButton color='error' onClick={() => handleRemove(index)}>
          <DeleteIcon />
        </IconButton>
      </FlexBox>
    );
  }

  return (
    <>
      <FlexBox>
        <Typography fontSize={'14px'} sx={{ alignContent: 'center' }}>
          Update Values
        </Typography>
        <IconButton color='primary' onClick={() => append(defaultUpdateValue)}>
          <AddCircleRoundedIcon />
        </IconButton>
      </FlexBox>
      {fields.map((field, index) => updateValueRow(field, index))}
    </>
  );
};
