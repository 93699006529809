import React, { useEffect, useMemo, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, IconButton, List, ListItem } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import {
  GRID_REORDER_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowOrderChangeParams,
  GridValueGetterParams,
  gridExpandedSortedRowIdsSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { GridRowReorderCell } from '@mui/x-data-grid-pro/components/GridRowReorderCell';
import { FlexTableBox } from 'components/FlexTableBox';
import { CompactGridWrapper } from 'components/grid/CompactGridWrapper';
import { renderCellBooleanCheck } from 'components/grid/GridCellBooleanCheck';
import { PipelineRule } from '../../../data/SuperDuperFiestaData';
import useMemoTranslation from '../../../hooks/UseMemoTranslation';
import {
  dataClassName,
  dataSourceName,
  description,
  entityName,
  executionOrder,
  field,
  issueName,
  nameField,
  needsReview,
  queryCondition,
  queryGroupBy,
  queryJoin,
  stepTypeName,
  value,
} from '../../../util/Constants';

export const PipelineRulesModalGrid = ({
  rows,
  setRows,
  interactableRows,
  removeRow,
}: {
  rows: PipelineRule[];
  setRows: React.Dispatch<React.SetStateAction<PipelineRule[]>>;
  interactableRows: PipelineRule[];
  removeRow: (rowId: number) => void;
}) => {
  const apiRef = useGridApiRef();

  const [targetRowIndex, setTargetRowIndex] = useState<number>(0);

  const columns = useColumns(interactableRows, removeRow);

  useEffect(() => {
    if (interactableRows.length === 0) {
      return;
    }

    const focusedRow = interactableRows.at(-1);

    if (focusedRow !== undefined) {
      const rowIndex = gridExpandedSortedRowIdsSelector(apiRef).findIndex((id: any) => id === focusedRow.id);

      if (targetRowIndex !== rowIndex && rowIndex > 0) {
        setTargetRowIndex(rowIndex);
      }
    }
  }, [apiRef, interactableRows, targetRowIndex]);

  useEffect(() => {
    if (targetRowIndex === 0) {
      return;
    }

    let selectedRowIds: GridRowId[] = [];

    interactableRows.forEach(ir => {
      if (ir.id !== undefined) {
        selectedRowIds.push(ir.id);
      }
    });

    setTimeout(() => {
      apiRef?.current?.scrollToIndexes({ rowIndex: targetRowIndex });
      apiRef?.current?.selectRows(selectedRowIds);
    });
  }, [targetRowIndex, apiRef, interactableRows]);

  const handleRowOrderChange = (params: GridRowOrderChangeParams) => {
    const tempRows = [...rows];
    const row = tempRows.splice(params.oldIndex, 1)[0];
    row.previousStepId = tempRows[params.targetIndex - 1]?.stepId;
    tempRows.splice(params.targetIndex, 0, row);

    setRows(
      tempRows.map((row, index) => ({
        ...row,
        id: index,
        executionOrder: index + 1,
        executionOrderDisplay: index + 1,
      }))
    );
  };

  function CustomNoRowsOverlay() {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          background: '#F0F0F0',
        }}
      >
        Add a rule
      </Box>
    );
  }

  function CustomReOrderIcon() {
    return <DragIndicatorIcon fontSize='small' />;
  }

  return (
    <FlexTableBox
      sx={{
        maxHeight: '40vh',
      }}
    >
      <CompactGridWrapper
        apiRef={apiRef}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        columns={columns}
        rows={rows}
        rowHeight={25}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          rowReorderIcon: CustomReOrderIcon,
        }}
      />
    </FlexTableBox>
  );
};

const useColumns = (interactableRows: PipelineRule[], removeRow: (rowId: number) => void): GridColDef[] => {
  const { t } = useMemoTranslation();

  function getField(params: GridValueGetterParams<PipelineRule>) {
    return params.row.stepUpdateValues?.map(suv => suv.field);
  }

  function getValue(params: GridValueGetterParams<PipelineRule>) {
    return params.row.stepUpdateValues?.map(suv => suv.value);
  }

  return useMemo(() => {
    const DeleteRow: React.FC<GridRenderCellParams> = params => {
      // Only allow removal of a row on new rules
      const isPresent = interactableRows.some(ir => {
        return ir.name === params.row.name && ir.stepId === null;
      });
      if (isPresent) {
        return (
          <IconButton color='error' size='small' onClick={() => removeRow(params.row.id)}>
            <DeleteIcon fontSize='small' />
          </IconButton>
        );
      } else {
        return <></>;
      }
    };

    return [
      {
        ...GRID_REORDER_COL_DEF,
        width: 40,
        renderCell: params => {
          const isPresent = interactableRows.some(ir => {
            return ir.name === params.row.name;
          });
          if (isPresent) {
            return <GridRowReorderCell {...params} />;
          }
        },
      },
      {
        field: 'executionOrderDisplay',
        headerName: t(executionOrder),
        width: 125,
      },
      {
        field: nameField,
        headerName: t(nameField),
        width: 250,
      },
      {
        field: description,
        headerName: t(description),
        width: 250,
      },
      {
        field: stepTypeName,
        headerName: t(stepTypeName),
        width: 150,
      },
      {
        field: entityName,
        headerName: t(entityName),
        width: 150,
      },
      {
        field: dataSourceName,
        headerName: t(dataSourceName),
        width: 150,
      },
      {
        field: dataClassName,
        headerName: t(dataClassName),
        width: 150,
      },
      {
        field: issueName,
        headerName: t(issueName),
        width: 150,
      },
      {
        field: needsReview,
        headerName: t(needsReview),
        width: 100,
        align: 'center',
        renderCell: renderCellBooleanCheck,
      },
      {
        field: queryCondition,
        headerName: t(queryCondition),
        width: 250,
      },
      {
        field: queryJoin,
        headerName: t(queryJoin),
        width: 250,
      },
      {
        field: queryGroupBy,
        headerName: t(queryGroupBy),
        width: 150,
      },
      {
        field: field,
        headerName: t(field),
        width: 100,
        valueGetter: getField,
        renderCell: (params: GridRenderCellParams<PipelineRule>) => (
          <Tooltip
            title={
              <List dense sx={{ listStyleType: 'disc' }}>
                {params.value?.map((v: string) => (
                  <ListItem
                    key={`rule_modal_grid_field_li_${v}`}
                    disablePadding
                    alignItems='flex-start'
                    sx={{ display: 'list-item' }}
                  >
                    {v}
                  </ListItem>
                ))}
              </List>
            }
          >
            {params.value ? params.value[0] : params.row.field}
          </Tooltip>
        ),
      },
      {
        field: value,
        headerName: t(value),
        width: 100,
        valueGetter: getValue,
        renderCell: (params: GridRenderCellParams<PipelineRule>) => (
          <Tooltip
            title={
              <List dense sx={{ listStyleType: 'disc' }}>
                {params.value?.map((v: string) => (
                  <ListItem
                    key={`rule_modal_grid_value_li_${v}`}
                    disablePadding
                    alignItems='flex-start'
                    sx={{ display: 'list-item' }}
                  >
                    {v}
                  </ListItem>
                ))}
              </List>
            }
          >
            {params.value ? params.value[0] : params.row.value}
          </Tooltip>
        ),
      },
      {
        field: 'active',
        headerName: 'Active',
        width: 75,
        align: 'center',
        renderCell: renderCellBooleanCheck,
      },
      {
        field: 'delete',
        headerName: t('delete'),
        type: 'string',
        flex: 0,
        width: 75,
        align: 'center',
        valueGetter: () => true,
        renderCell: DeleteRow,
      },
    ];
  }, [t, interactableRows, removeRow]);
};
