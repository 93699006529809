import React, { useContext, useEffect, useMemo, useState } from 'react';
import useAuth from '../../auth/UseAuth';
import { TextField } from '@mui/material';
import { AutocompleteWrapper } from '../../components/AutocompleteWrapper';
import { FlexBox } from '../../components/FlexBox';
import PipelineReviewContext from '../../contexts/PipelineReviewContext';
import { ActionButton } from '../../components/DialogOpenButton';
import { GridColDef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { FlexTableBox } from '../../components/FlexTableBox';
import { CompactGridWrapper } from '../../components/grid/CompactGridWrapper';
import {
  GetPipelineReviewIssues,
  GetPipelineReviewTypes,
  SuperDuperFiestaPipelineReviewIssues,
} from '../../data/SuperDuperFiestaData';
import { map } from 'lodash';
import { LoadState, LoadingProps } from '../../components/LoadingStateUtil';

export const PipelineReview = ({ loadingProps }: { loadingProps: LoadingProps }) => {
  const { accessToken } = useAuth();

  const {
    dataClasses,
    dataSources,
    entities,
    stages,
    pipelineReviewState,
    pipelineReviewDispatch,
    pipelineReviewParams,
    clearContextSelection,
    setResetPaginationModel,
  } = useContext(PipelineReviewContext);

  const columns = useColumns();
  const [rows, setRows] = useState<ReadonlyArray<SuperDuperFiestaPipelineReviewIssues>>([]);
  const [contentTypes, setContentTypes] = useState<ReadonlyArray<number>>([]);
  const [, setLoadingState] = loadingProps;

  useEffect(() => {
    pipelineReviewDispatch({ type: 'update_stage', stage: stages.find(s => s.name === 'selection') });
  }, [stages, pipelineReviewDispatch]);

  useEffect(() => {
    return LoadState(setLoadingState, async () => {
      if (pipelineReviewParams !== null) {
        // Remove dataClassId so types list doesnt get filtered to 1
        const { dataClassId: _, ...typeParams } = pipelineReviewParams;
        const typeData = await GetPipelineReviewTypes(typeParams, accessToken);
        const countData = await GetPipelineReviewIssues(pipelineReviewParams, accessToken);
        setRows(map(countData, (row, index) => ({ ...row, id: index })));
        setContentTypes(map(typeData, td => td.dataClassId));
      } else {
        setRows([]);
      }
    });
  }, [accessToken, setLoadingState, pipelineReviewParams]);

  function filterGridContent(rowSelected: GridRowSelectionModel) {
    const rowId: any = rowSelected[0];
    if (rowId) {
      const selectedRow = rows[rowId];
      pipelineReviewDispatch({ type: 'update_selectedIssue', issueId: selectedRow.issueId });
    }
  }

  function onFilterChange(filterType: string, value: any) {
    if (filterType === 'update_entity') {
      resetFilters();
      pipelineReviewDispatch({ type: 'update_stage', stage: stages.find(s => s.name === 'selection') });
      pipelineReviewDispatch({ type: filterType, entity: value });
    }
  }

  function resetFilters() {
    clearContextSelection();
    setResetPaginationModel(true);
  }

  return (
    <FlexBox sx={{ flexDirection: 'column', gap: 1, width: '100%', height: '100%' }}>
      <AutocompleteWrapper
        fullWidth
        size='small'
        value={pipelineReviewState.entity || null}
        options={entities}
        getOptionLabel={entity => entity.displayName}
        renderInput={params => <TextField {...params} label={'Entity'} margin='dense' required />}
        onChange={(_, value) => onFilterChange('update_entity', value)}
      />
      <AutocompleteWrapper
        fullWidth
        size='small'
        value={pipelineReviewState.dataClass || null}
        options={dataClasses.filter(dc => dc.dataClassId && contentTypes.includes(dc.dataClassId))}
        getOptionLabel={dataClass => dataClass.name}
        renderInput={params => <TextField {...params} label={'Type'} margin='dense' />}
        onChange={(_, value) => pipelineReviewDispatch({ type: 'update_dataClass', dataClass: value })}
      />
      <AutocompleteWrapper
        fullWidth
        size='small'
        value={pipelineReviewState.stage || null}
        options={stages}
        getOptionLabel={stage => stage.displayName}
        renderInput={params => <TextField {...params} label={'Stage'} margin='dense' required />}
        onChange={(_, value) => pipelineReviewDispatch({ type: 'update_stage', stage: value })}
      />
      <AutocompleteWrapper
        fullWidth
        size='small'
        value={pipelineReviewState.dataSource || null}
        options={dataSources}
        getOptionLabel={dataSource => dataSource.displayName}
        renderInput={params => <TextField {...params} label={'Source'} margin='dense' />}
        onChange={(_, value) => pipelineReviewDispatch({ type: 'update_dataSource', dataSource: value })}
      />
      <FlexTableBox sx={{ height: '100%' }}>
        <CompactGridWrapper
          columns={columns}
          rows={rows}
          getRowHeight={() => 'auto'}
          disableMultipleRowSelection={true}
          onRowSelectionModelChange={rowId => {
            filterGridContent(rowId);
          }}
          enableCustomAutoColumnResizing={true}
          initialState={{
            sorting: {
              sortModel: [{ field: 'reviewCount', sort: 'desc' }],
            },
          }}
        />
      </FlexTableBox>
      <ActionButton
        sx={{ p: 0 }}
        title={'Clear Filters'}
        onClick={() => {
          resetFilters();
        }}
        disableMarginLeft={true}
      >
        <></>
      </ActionButton>
    </FlexBox>
  );
};

const useColumns = (): GridColDef[] => {
  return useMemo(() => {
    return [
      {
        field: 'issueName',
        headerName: 'Issues',
        width: 200,
      },
      {
        field: 'issueCount',
        headerName: '# of Failures',
        width: 100,
        align: 'right',
        valueFormatter: ({ value }) => value && Number(value?.toFixed(2)).toLocaleString(),
      },
    ];
  }, []);
};
